// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #FFFFFF;
$cream: #F5F4ED;
$black: #040522;
$primary: #865231;
$second: #E08E2F;
$green: #7FBF17;
$red: #D1603E;
$orange: #E08E2F;
$blue-light: #58E0EB;
$blue: #0E457C;
$grey: rgba(#040522, 0.35);
$grey-light: #F0F0F0;
$black-overlay-light: rgba(0, 0, 0, 0.5);


// Specific
// =============================================================================
// Link
$link-color: $black;
$link-focus-color: $primary;
$link-hover-color: $primary;
// Selection
$selection-text-color: $primary;
$selection-background-color: #FFFFFF;

// Social Colors
// =============================================================================
$facebook-color: #3B5998;
$instagram-color: #E1306C;
$youtube-color: #CD201F;
$twitter-color: #1DA1F2;