.c-button {
  padding: rem($unit-tinier) rem($unit-small);
  border-radius: rem(32px);
  color: $primary;
  transition: $speed $easing;
  display: inline-flex;
  align-items: center;
  border: 2px solid transparent;

  .c-button_label {
    
  }

  &.u-bg-green{
    &:hover{
      color: $green;
      background-color: $white;
      border: 2px solid $green;
    }
  }

  .c-button_deco {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.-text{
    background-color: $green;
    color: $white;
  }

  &.-white {
    background-color: $white;
    color: $primary;
    .c-button_label {

    }
  }

  &.-second {
    background-color: $second;
    color: $black;
  }

  &.-round {
    width: rem(38px);
    height: rem(38px);
    border-radius: 50%;
    justify-content: center;
    padding: 0;
    &.-big{
      width: rem(76px);
      height: rem(76px);
      transition: $speed $easing;

      &:hover{
        background-color: $primary;

        svg{
          path{
            fill: $white;
          }
        }
      }

      svg{
        width: rem(28px);
      }
    }
  }

  svg {
    width: rem(12px);
    max-height: rem(20px);
  }

  @include u-hocus {
    background-color: $primary;
    color: $white;
    
  }

  &.-disabled {
    cursor: default;
    opacity: 0.5;
  }
}
