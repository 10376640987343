.c-footer{
    .c-footer_top{
        height: rem(145px);
        position: relative;
        overflow: hidden;
        svg{
            position: absolute;
            top: 0;
            left: 35vw;
            transform: translateX(-50%);
            width: rem(750px);
        }
    }
    .c-footer_middle{
        .c-footer_middle_social{
            span{
                svg{
                    height: rem(33px);
                    max-height: inherit;
                }
            }
        }

        img{
            width: rem(134px);
        }
    }
    .c-footer_bottom{
        height: rem(70px);
        background-color: #46250F;
        display: flex;
        align-items: center;

        .o-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .c-footer_bottom_menu{
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }

    @media(max-width: $from-medium){
        .c-footer_middle{
            .c-footer_middle_content{
                gap: 4rem;

                .o-layout_item{
                    text-align: center !important;
                }
            }
        }
    }
}
