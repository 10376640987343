// ==========================================================================
// Main
// ==========================================================================

// Settings
// ==========================================================================
@import "settings/config.eases";
@import "settings/config.colors";
@import "settings/config";

// ==========================================================================
// Tools
// ==========================================================================
@import "tools/functions";
@import "tools/mixins";
@import "tools/fonts";
@import "tools/layout";
@import "tools/widths";
// @import "tools/family";

// Generic
// ==========================================================================
@import "node_modules/normalize.css/normalize";
@import "generic/generic";
@import "generic/media";
@import "generic/form";
@import "generic/button";

// Elements
// ==========================================================================
@import "elements/fonts";
@import "elements/page";

// Objects
// ==========================================================================
@import "objects/scroll";
@import "objects/container";
@import "objects/ratio";
@import "objects/layout";
// @import "objects/crop";
// @import "objects/table";

// Vendors
// ==========================================================================
// @import "vendors/vendor";

// Components
// ==========================================================================
@import "components/scrollbar";
@import "components/transition";
@import "components/heading";
@import "components/button";
@import "components/figure";
@import "components/form";
@import "components/accordion";
@import "components/header";
@import "components/footer";
@import "components/nav";
@import "components/card";
@import "components/loader";
@import "components/flap";
@import "components/table";
@import "components/tab";
@import "components/text";
@import "components/slider";
@import "components/navbloc";
@import "components/modal";
@import "components/video";
@import "components/datepicker";
@import "components/badge";

@import "blocks/accueil";
@import "blocks/chiffres";
@import "blocks/equipe";
@import "blocks/etapes";
@import "blocks/fichiers";
@import "blocks/galerie";
@import "blocks/relation";
@import "blocks/slider";
@import "blocks/tiroirs";
@import "blocks/video";
@import "blocks/cards";
@import "blocks/cta.scss";
@import "blocks/onglet";
@import "blocks/organigramme";
@import "blocks/timeline";
@import "blocks/icones";
@import "blocks/media-texte";
@import "blocks/avant-apres";
@import "blocks/stats";
@import "blocks/fichiers";
@import "blocks/reservation";
@import "blocks/parcours";

// Templates
// ==========================================================================
// @import "templates/template";

// Utilities
// ==========================================================================
@import "utilities/ratio";
@import "utilities/widths";
@import "utilities/align";
@import "utilities/helpers";
@import "utilities/states";
@import "utilities/spacing";
// @import "utilities/print";
@import "utilities/colors";

@import "trinity/settings";
@import "trinity/login";

@import '~js-datepicker/dist/datepicker.min.css';
