.c-text{
    a:not(.c-button) {
        color: $second;
        text-decoration: underline;
    }

    ul {
        padding-left: 0;
        li {
            list-style-type: none;
            position: relative;
            padding-left: calc(#{rem(10px)} + #{rem($unit-tiny)});
            margin-bottom: rem($unit-tinier);
            &:last-of-type {
                margin-bottom: 0;
            }
            &::before {
                content: " ";
                position: absolute;
                left: 0;
                width: rem(10px);
                height: rem(10px);
                // background-color: currentColor;
                background-color: $second;
                border-radius: 50%;
                top: calc(#{$font-size} - #{rem(10px)});
            }
        }
    }
    ol {
        counter-reset: ol_counter; /* on initialise et nomme un compteur */
        padding: 0;
        li {
            padding: 0;
            list-style-type: none;
            counter-increment: ol_counter; /* on incrémente le compteur à chaque nouveau li */
            position: relative;
            padding-left: calc(#{rem(12px)} + #{rem($unit-tiny)});
            margin-bottom: rem($unit-tinier);
            &::before {
                content: counter(ol_counter)"."; /* on affiche le compteur */
                width: rem(12px);
                height: rem(12px);
                position: absolute;
                font-weight: $medium;
                left: 0;
            }
        }
    }
}
