.b-video {
    position: relative;
    .b-video_wrap {
        position: relative;
        border-radius: rem(20px);
        overflow: hidden;
        box-shadow: 0 rem(10px) rem(30px) rgba($black, 0.2);
        height: rem(414px);
    }
    .b-video_player_wrap {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba($color: $green, $alpha: 0.7);
            z-index: 3;
        }
        .b-video_player {
            position: relative;
            z-index: 4;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .o-ratio_content_bg {
            transition: $speed $easing;
        }
        .c-button_icon {
            transition: $speed $easing;
            transform-origin: center;
        }
        &:hover {
            .o-ratio_content_bg {
                transform: scale(1.03);
            }
            .c-button_icon {
                transform: rotate(120deg);
            }
        }
    }

    .b-video_deco{
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(-80%, 5%);
        z-index: -1;

        svg{
            width: rem(748px);
        }
    }
    .b-video_deco2{
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-40%, 25%);
        z-index: -1;

        svg{
            width: rem(246px)
        }
    }

    @media(max-width: $from-large) {
        .b-video_deco{
            svg{
                width: 15rem;
            }
        }
        .b-video_deco2{
            display: none;
        }

        .b-video_wrap{
            height: fit-content;
        }
    }

    @media(max-width: $from-small){
        .c-heading{
            &.-h3{
                font-size: $font-size-h3;
                text-align: center;
            }
        }
    }
}
