.c-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: rem($header-height);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 5px 20px rgba($primary, 0.08);

  .c-header_logo {
    img {
      width: rem(168px);
      min-width: rem(90px);
      position: relative;
      top: 3rem;
      right: 2rem;
    }

    * {
      transition: $speed $easing;
    }

    .delay0 {
      transition-delay: 0.2s;
    }

    .delay1 {
      transition-delay: 0.1s;
    }

    .delay2 {
      transition-delay: 0s;
    }
  }

  
  @media (min-width: $from-small) {
    padding: 0 rem(40px);
  }

  @media (max-width: $to-small) {
    padding: 0 rem(20px);
  }
}

.c-header_nav_burger {
  display: flex;
  align-items: center;
  grid-gap: rem($unit-tiny);
  color: $second;

  .c-header_nav-icon {
    position: relative;
    width: rem(50px);
    height: rem(50px);
    cursor: pointer;
    display: block;
    position: relative;
    transition: $speed $easing;

    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 2px;
      width: rem(23px);
      background: $primary;
      border-radius: 2px;
      opacity: 1;
      left: rem(13.5px);
      transform: rotate(0deg);
      transition: $speed $easing;

      &:nth-child(1) {
        top: rem(18px);
      }

      &:nth-child(2) {
        top: rem(28px);
      }
    }
  }

  &.is-active {
    .c-header_nav-icon {
      span {
        &:nth-child(1) {
          top: rem(25px);
          transform: rotate(135deg);
        }

        &:nth-child(2) {
          top: rem(25px);
          transform: rotate(-135deg);
        }
      }
    }
  }

  &:hover:not(.is-active) {
    .c-header_nav-icon {
      span {
        &:nth-child(1) {
          top: rem(20px);
        }

        &:nth-child(2) {
          top: rem(26px);
        }
      }
    }
  }
}
