.b-chiffres {
    .b-chiffres_item{
        @media (max-width: $from-small){
            display: flex;
            gap: 0.5rem;

            div{
                width: 3rem;
            }
        }
    }
}