.c-heading {
  line-height: $line-height-h;

  &.-noMargin {
    margin-bottom: 0;
  }

  &.-roboto {
    font-family: 'Roboto';
  }

  &.-larger{
    font-size: 160px;
    font-weight: $bold;
    opacity: 0.12;
    text-transform: uppercase;
    padding: 0 0.5rem;
    margin-bottom: -6rem;

    @media(max-width: $from-large){
      font-size: 120px;
    }

    @media(max-width: $from-medium) {
      font-size: 70px;
      margin-bottom: inherit;
      opacity: inherit;
    }

    @media(max-width: $from-small) {
      font-size: 35px;
    }
  }

  &.-h1 {
    font-size: rem($font-size-h1);
    font-weight: $bold;
    line-height: $line-height-default;

    @media (max-width: $to-small) {
      font-size: calc(#{rem($font-size-h1)} - 10px);
    }
  }

  &.-h2 {
    font-size: rem($font-size-h2);

    @media (max-width: $to-small) {
      font-size: calc(#{rem($font-size-h2)} - 5px);
    }
  }

  &.-large {
    font-size: rem($font-size-large);

    @media (max-width: $to-small) {
      font-size: calc(#{rem($font-size-large)} - 5px);
    }
  }

  &.-h3 {
    font-size: rem($font-size-h3);

    @media (max-width: $to-small) {
      font-size: calc(#{rem($font-size-h3)} - 5px);
    }
  }

  &.-h4 {
    font-size: rem($font-size-h4);
    font-weight: $bold;

    @media (max-width: $to-medium) {
      font-size: calc(#{rem($font-size-h4)} - 4px);
    }
  }

  &.-h5 {
    font-size: rem($font-size-h5);
  }

  &.-h6 {
    font-size: rem($font-size-h6);
    text-transform: uppercase;
    letter-spacing: 0.8px;

    &.-lowercase {
      text-transform: inherit;
      margin-bottom: 0;
    }
  }

  &.-upper {
    text-transform: uppercase;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}