.b-cta{
    .b-cta_content{
        padding: 4rem 8rem;
        border-radius: 1rem;
    }
    
    .b-cta_btn{
        .c-button{
            background-image: linear-gradient(to right top, $primary, $second);
            color: $black;
            padding: 0.8rem 2rem;
            font-weight: bold;
            transition: 0.3s ease-out;

            &:hover{
                background-image: linear-gradient(to right top, $second, $primary);
                transform: scale(1.15);
            }
        }
    }

    @media (max-width: $from-medium){
        .b-cta_content{
            padding: 4rem 3rem;
        }
    }
}
