.c-modal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    transition: $speed $easing;
    .c-modal_bg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($black, 0.2);
    }
    .c-modal_container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem($unit);
        position: relative;
        height: 100%;
        pointer-events: none;
        .c-modal_wrap {
            max-width: rem(700px);
            width: 100%;
            background-color: $white;
            border-radius: 8px;
            position: relative;
            pointer-events: auto;
            // box-shadow: 0 10px 30px rgba($shadow, 0.16);
            overflow: hidden;
            .c-modal_head {
                background-color: $primary;
                color: $white;
                padding: rem($unit-tiny) rem($unit-small);
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .c-modal_content {
                position: relative;
                z-index: 2;
                padding: rem($unit-small);
                max-height: 80vh;
                overflow: auto;
            }
            .c-modal_image {
                height: rem(200px);
                position: relative;
            }
            .hr {
                flex: 1;
                height: 1px;
                background-color: $black;
            }
        }
    }
    &.is-open {
        visibility: visible;
        opacity: 1;
    }
}
  