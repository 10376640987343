.c-card {
  position: relative;
  padding: rem($unit-small);
  border-radius: rem(8px);
  box-shadow: 0 rem(10px) rem(30px) rgba($black, 0.15);
  overflow: hidden;

  .c-card_foot{
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }

  figure{
    overflow: hidden;
    img{
      transition: $speed $easing;
    }
  }

  &:hover{
    figure{
      img{
        transform: scale(1.05);
      }
    }
  }

  &.-listing{
    
  }

  &.-slider{
    background-color: rgba($color: $second, $alpha: 0.15);
    border: inherit;
  }

  &.-page {
    @media (max-width: $to-small) {
      transform: none!important;
    }
  }
  &.-actualite {
    padding: 0;
    border-radius: rem(24px);
    overflow: hidden;

    figure{
      position: relative;
      max-height: 8.5rem;
      
      span{
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0 rem(15px) 0 0;
        padding: 0.8rem 1.2rem;
      }
    }

    &.-velo{
      height: 100%;

      figure{
        img{
          height: rem(200px);
        }
      }
    }
  }

  &.-velo{
    &.-tax{
      padding: 0;
      box-shadow: inherit;
      position: inherit;

      .c-card_image{
        // height: rem(270px);
        background-color: $white;
        border-radius: rem(20px);
        overflow: hidden;

        // figure{
        //   height: inherit;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;

        //   img{
        //     width: rem(200px);
        //     height: fit-content;
        //   }
        // }
     }
    }
  }
}
