.u-c-primary {
  color: $primary;
}
.u-c-second {
  color: $second;
}
.u-c-black {
    color: $black;
}
.u-c-white {
    color: $white;
}
.u-c-red{
  color: red;
}
.u-bg-primary {
  background-color: $primary;
}
.u-bg-second {
  background-color: $second;
}
.u-bg-black {
    background-color: $black;
}
.u-bg-white {
    background-color: $white;
}
.u-bg-green {
  background-color: $green;
}
.u-bg-orange {
  background-color: $orange;
}
.u-bg-blueLight {
  background-color: $blue-light;
}
.u-bg-blue {
  background-color: $blue;
}

body {
  &[data-bg="-primary"] {
    background-color: $primary;
  }
  &[data-bg="-second"] {
    background-color: $second;
  }
  &[data-bg="-black"] {
    background-color: $black;
    color: $white;
  }
  &[data-bg="-white"] {
    background-color: $white;
    color: $black;
  }
}
