.c-nav {
  .c-nav_wrap {
    background-color: $black;
    overflow-x: auto;
    height: 100vh;
    position: relative;

    .o-container {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: '';
      height: 35%;
      width: 100%;
      background-color: $second;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 0;
      transform-origin: top;
      transition: 0.8s $easing 0.2s;
    }
  }

  .c-nav_image-bg {
    transform: translateY(-100%);
    transition: 0.3s $easing;
    transition-delay: 0.7s;

    @media (max-width: $to-small) {
      display: none;
    }
  }

  .c-nav_primary {
    display: flex;
    gap: 2rem;
    align-items: center;

    a {
      color: $primary;
      div {
        position: relative;

        span {
          transition: $speed $easing;
          display: inline-block;
        }
      }

      &:hover{
        color: $second;
      }
    }
  }

  .c-nav_primary_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .c-nav_primary_icon {
      transform: translateY(-5rem);
      transition: 0.6s $easing 0.4s;

      svg {
        width: 5rem;
      }
    }
  }
}

.c-nav_component {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  //visibility: hidden;
  transform: translateY(calc(-100% - #{rem($header_height)}));
  transition: transform 1s $easing2;
  height: 100vh;
  overflow: hidden;

  .c-nav_wrap {
    background-color: $primary;
    overflow-x: auto;
    height: 100vh;
    position: relative;

    .o-container {
      position: relative;
      z-index: 2;
    }
  }

  .c-nav_image-bg {
    transform: translateY(-100%);
    transition: 0.3s $easing;
    transition-delay: 0.7s;

    @media (max-width: $to-small) {
      display: none;
    }
  }

  .c-nav_primary_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .c-nav_primary_icon {
      transform: translateY(-5rem);
      transition: 0.6s $easing 0.4s;

      svg {
        width: 5rem;
      }
    }

    .c-nav_primary_link {
      font-size: 2rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: center;
      color: $white;

      &:not(:last-of-type) {
        position: relative;
        padding: 0.3rem 2rem;
        margin-bottom: 2rem;
      }
    }
  }

  .c-nav_secondary_container {

    .c-nav_secondary_left,
    .c-nav_secondary_right {
      padding-top: 1rem;
      border-top: 2px solid $white;
      width: rem(305px);
    }

    .c-nav_secondary_right {
      span {
        width: 1rem;
        height: 2px;
        background-color: $white;
      }
    }

    .c-nav_secondary_center {
      position: relative;
      width: rem(250px);
      height: rem(250px);

      span {
        display: flex;
        flex-direction: column;
        align-items: center;

        &::after {
          content: '';
          height: 4px;
          width: 2rem;
          background-color: $white;
          margin-top: 1rem;
        }
      }

      &::before {
        content: '';
        width: rem(250px);
        height: rem(250px);
        border-radius: 50%;
        background-color: rgba($color: $white, $alpha: 0.1);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: $speed $easing;
      }

      &:hover {
        &::before {
          width: rem(230px);
          height: rem(230px);
        }
      }
    }

    @media (max-width: $from-medium) {
      flex-direction: column;

      .c-nav_secondary_left {
        order: 2;
        margin-top: 2rem;
      }

      .c-nav_secondary_right {
        order: 3;
        border-top: inherit;
      }

      .c-nav_secondary_center {
        order: 1;
        padding-top: inherit;
      }
    }
  }

  .b-accueil-intro_deco {
    transition: 0.8s $easing 0.6s;

    &.b-accueil-intro_deco4 {
      transform: rotate(150deg) translateY(-15rem) !important;
    }

    &.b-accueil-intro_deco2 {
      transform: rotate(30deg) translateY(15rem) !important;
    }
  }
}

.has-navOpen {
  .c-nav_component {
    visibility: visible;
    transform: translateY(0);

    .c-nav_wrap {
      &::after {
        height: 0;
      }
    }

    .c-nav_primary {
      opacity: 1;
    }

    .c-nav_shop {
      opacity: 1;
      transform: translateY(0);
    }

    .c-nav_image-bg {
      transform: translateY(0);
    }

    .c-nav_primary_container {
      .c-nav_primary_icon {
        transform: translateY(0);
      }
    }

    .b-accueil-intro_deco {
      transform: translateY(0) !important;
    }
  }
}
