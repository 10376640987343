.b-reservation_step {
    text-align: center;
    z-index: 2;
    position: relative;
    
    .b-reservation_step_container {
        background-color: $white;
        border-radius: rem(20px);
        padding: 2rem;
    }

    .b-reservation_round {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        justify-content: center;

        .step {
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
            border: 1px solid $primary;
            background-color: transparent;
            opacity: 0.5;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: default;
        }
        hr{
            border-top: 1px solid $primary;
            width: rem(15px);
        }
        .is-active{
            opacity: 1;
            border: 3px solid $primary;
            cursor: pointer;
            
        }
        .is-validate{
            opacity: 1;
            border: inherit;
            background-color: $green;
            color: white;
            font-weight: $medium;
            opacity: 1;
            border: 3px solid $green;
            cursor: pointer;
        }
    }
}

// .b-reservation{
//     .b-reservation_wrap{
//         overflow: hidden;
//     }
//     .b-reservation_container{
//         display: grid;
//         grid-template-columns: 100% 100% 100%;
//         gap: 3rem;
//         transition: $speed $easing;
//     }
//     form{
//         padding: 2rem;
//         border: 3px solid $primary;
//         border-radius: rem(20px);
//         overflow: hidden;
//         position: relative;

//         input{
//             width: fit-content;
//             margin-top: 0.75rem;
//             padding: 0.25rem 0.5rem 0.25rem 1rem;
//             border: 1px solid;
//             border-radius: 20px;
//         }

//         input[type="text"]{
//             width: rem(150px);
//         }

//         .b-reservation_deco{
//             position: absolute;
//             top: -20%;
//             right: -20%;
//             z-index: -1;
//         }

//         .b-reservation_input_radio{
//             display: flex;
//             width: fit-content;
//             align-items: center;
//             gap: 0.35rem;
//             margin-top: 0.75rem;

//             input{
//                 appearance: inherit;
//                 -webkit-appearance: auto;
//                 margin-top: 0;
//             }
//         }
//     }

//     #bikes{
//         display: grid;
//         grid-template-columns: repeat(3, 1fr);
//         gap: 2rem;
//     }

//     #form-contact{
//         display: none;
//         flex-direction: column;
//         height: fit-content;
        
//         .form-container{
//             width: fit-content;

//             input[type='text']{
//                 width: 12rem;
//             }
//         }
//     }


//     .qs-datepicker-container{
//         width: rem(290px);
//     }

//     @media(max-width : $from-medium){
//         form{
//             .b-reservation_deco{
//                 display: none;
//             }
//         }

//         #bikes{
//             grid-template-columns: 1fr 1fr;
//         }
//     }

//     @media(max-width :$from-tiny){
//         #bikes{
//             grid-template-columns: 1fr;
//         }

//         #form-contact{
//             .form-container{
//                 width: 100%;
//                 .form-name{
//                     width: 100%;
//                     flex-direction: column;

//                     div{
//                         width: 100%;

//                         input[type='text']{
//                             width: 100%;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }


.b-reservation {
    .b-reservation_deco {
        overflow: hidden;
        svg {
            transform: translateY(rem(-$unit));
        }
    }

    .b-reservation_form_wrap {
        border: 3px solid $primary;
        border-radius: rem(20px);
    }
}