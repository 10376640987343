.c-datepicker {
    .qs-controls {
        background-color: $primary;
        color: $white;
        padding: rem($unit-tinier);
    }

    .qs-arrow.qs-left:after {
        border-right-color: $white;
    }

    .qs-arrow.qs-right:after {
        border-left-color: $white;
    }

    .qs-squares {
        
    }

    .qs-square {
        border-radius: 50%;
    }

    .qs-day {
        color: $primary;
    }

    .qs-current {
        background-color: rgba($primary, 0.1);
    }

    .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
        background-color: $primary;
        color: $white;
    }

    .qs-active, .qs-range-end, .qs-range-start {
        background: $primary;
        color: $white;
    }
}