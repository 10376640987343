// ==========================================================================
// Base / Page
// ==========================================================================

//
// Simple page-level setup.
//
// 1. Set the default `font-size` and `line-height` for the entire project,
//    sourced from our default variables.
// 2. Ensure the page always fills at least the entire height of the viewport.
//
html {
    min-height: 100%; /* [2] */
    color: $primary;
    font-family: $font-family;
    font-weight: $light;
    line-height: $line-height; /* [1] */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (max-width: $to-small) {
        font-size: $font-size - 2px;
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        font-size: $font-size - 2px;
    }

    @media (min-width: $from-medium) and (max-width: $to-large) {
        font-size: $font-size - 1px;
    }

    @media (min-width: $from-large) and (max-width: $to-huge) {
        font-size: $font-size; /* [1] */
    }

    @media (min-width: $from-huge) and (max-width: $to-gigantic) {
        font-size: $font-size + 1px;
    }

    @media (min-width: $from-gigantic) and (max-width: $to-colossal) {
        font-size: $font-size + 2px;
    }

    @media (min-width: $from-colossal) {
        font-size: $font-size + 4px;
    }

    &.is-loading {
        cursor: wait;
    }

    &.has-scroll-smooth {
        overflow: hidden;
    }

    &.has-scroll-dragging {
        user-select: none;
    }
}

body {
    transition: background-color $speed $easing;
    .has-scroll-smooth & {
        overflow: hidden;
    }
    background-color: $cream;
}

main {
//   padding-top: rem($header-height);
  overflow: hidden;
}

.app {
  opacity: 0;
  transition: opacity 0.6s $easing;
}

.is-animated .app {
  opacity: 1!important;
}

::selection {
    background-color: $selection-background-color;
    color: $selection-text-color;
    text-shadow: none;
}

a {
    color: $link-color;
    transition: 0.3s $easing;

    @include u-hocus {
        color: $link-hover-color;
    }
}

.e-page_intro{
    &.-velo{
        figure{
            img{
                width: 90%;
                min-width: rem(280px);
                border-radius: rem(20px);
            }
        }

        @media(max-width: 550px){
            .e-page_intro{
                flex-direction: column;

                .e-page_intro_side{
                    width: 100% !important;
                }
            }
        }
    }

    .e-page_content{
        .swiper-wrapper{
            .c-card{
                border: 1px solid $primary;
                margin: 1px;
                box-shadow: inherit;
            }
            figure{
                img{
                    width: 100%;
                }
            }
        }
    }
}

.e-page_intro_top{
    margin-top: $header-height;
    // height: rem(340px);
    background-color: rgba($color: $second, $alpha: 0.16);

    .o-container{
        height: 100%;
    }

    .e-page_intro_top_title{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}
