@include font-face($font-rubik,
    "fonts/Rubik/Rubik"

) {
    font-style: normal;
    font-weight: 400;
}

@include font-face($font-rubik,
    "fonts/Rubik/Rubik-Medium"

) {
    font-style: normal;
    font-weight: 500;
}

@include font-face($font-rubik,
    "fonts/Rubik/Rubik-SemiBold"

) {
    font-style: normal;
    font-weight: 600;
}

@include font-face($font-rubik,
    "fonts/Rubik/Rubik-Bold"

) {
    font-style: normal;
    font-weight: 700;
}