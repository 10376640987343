.b-accueil{
    margin-top: $header-height;
    position: relative;

    .b-accueil_header{
        position: relative;

        .b-accueil_slogan{
            position: absolute;
            top: 100%;
            left: $unit;
            transform: translateY(-55%);

            svg{
                width: rem(600px);
            }

            span{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-7deg);
                width: rem(381px);
                height: rem(381px);
                border-radius: 50%;
                background-color: $green;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        figure{
            img{
                min-height: rem(200px);
            }
        }
    }

    .b-accueil_content{
        position: relative;
        
        .c-text{
            margin: 0 0 0 auto;
        }
    }

    .b-accueil_deco{
        position: absolute;
        left: -6rem;
        bottom: 0;
        z-index: -1;

        svg{
            width: rem(130px);
        }
    }

    .b-accueil_deco2{
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(70%, -60%);
        z-index: -1;
    }

    .b-accueil_liens{
        display: grid;
        align-items: top;
        grid-template-columns: 1fr 1fr;
        gap: $unit;

        .b-accueil_liens_title{
            display: flex;
            align-items: center;
            justify-content: space-between;

            span{
                width: rem(38px);
                height: rem(38px);
                background-color: $green;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                svg{
                    width: rem(13px);
                }
            }
        }

        .b-accueil_lien{
            figure{
                border-radius: rem(20px);
                overflow: hidden;
                height: rem(267px);

                img{
                    transition: $speed $easing;
                }
            }
            &:hover{
                img{
                    transform: scale(1.05);
                }
            }
        }
    }

    @media(max-width: $from-large){
        .b-accueil_header {
            .b-accueil_slogan {
                svg {
                    width: rem(300px);
                }
                
                span {
                    width: rem(190px);
                    height: rem(190px);
                    font-size: $font-size-h1;
                }
            }
        }

        .b-accueil_deco2{
            display: none;
        }
    }

    @media(max-width: $from-tiny){
        .b-accueil_liens{
            grid-template-columns: 1fr;
        }

        .b-accueil_deco{
            opacity: 0.3;
        }
    }
}
