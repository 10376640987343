.b-parcours{
    .b-parcours_wrap{
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        gap: 5rem;

        iframe{
            width: 100%;
            min-height: rem(270px);
            border: 2px solid $primary;
            border-radius: rem(27px);
        }
    }

    @media(max-width: $from-small){
        .b-parcours_wrap{
            grid-template-columns: 1fr;
            gap: 2rem;
        }
    }
}
